import React, { useEffect } from 'react'

import 'twin.macro'
import { navigate } from 'gatsby'

import SEO from 'components/shared/seo'
import Layout from 'mx/layout'
import { Section } from 'mx/section'

const prospectIds = [
  'ckar2val0ymmq07530vbgkjcs',
  'ckp03xxea873941g9j7tfac9zk',
  'ckp4r95641153051g7ivh8aupxw',
  'cktp98149646711gcnxzrhyafm',
  'ckufua67l723551g9ait7wfr85',
]

const EstimatePage = () => {
  const randomProspectId =
    prospectIds[Math.floor(Math.random() * prospectIds.length)]

  useEffect(() => {
    navigate(`/welcome/${randomProspectId}/estimate/results`)
  }, [])

  return (
    <Layout>
      <Section tw="h-screen flex justify-center">
        <h1 className="h1" tw="font-thin leading-tight md:leading-tighter">
          Loading estimator...
        </h1>
      </Section>
    </Layout>
  )
}

export default EstimatePage

export const Head = () => <SEO description="" title="Estimate" />
